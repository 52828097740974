import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_CONTACT } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import pin from "../../../../common/assets/image/webApp/pin.png";
import ContactForm from "../../ContactForm/Section";
import mapboxgl from "mapbox-gl";

import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  TopHeading,
  ColumnContents,
} from "./section.style";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const Section = () => {
  const { sections } = SECTION_CONTACT;
  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiY2FtYmVyb3MiLCJhIjoiY2xlejRhM3hwMjN0aDN2cW84YnJ5NzQ0dSJ9.UbMConZiw67DZgokTR6HIA"; // Set your mapbox token here
  return (
    <ColumnContents>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections.phone} />
              <Text as="p" content={sections.phone_content_act} />
              <Text as="p" content={sections.phone_content_vic} />
              <Heading as="h2" content={sections.address} />
              <Text as="p" content={sections.address_content_act} />
              <Text as="p" content={sections.address_content_vic} />
            </Box>
            <Box className="blockTitle mapContainer">
              <Map
                initialViewState={{
                  longitude: 146.3149,
                  latitude: -36.3649,
                  zoom: 5,
                }}
                style={{ width: 600, height: 400 }}
                mapStyle="mapbox://styles/camberos/cj8p9taw19hm92rqldo3p39k6"
                mapboxAccessToken={MAPBOX_TOKEN}
              >
                <Marker
                  longitude={144.9631}
                  latitude={-37.8136}
                  anchor="bottom"
                >
                  <img width="40" height="40" src={pin} />
                </Marker>
                <Marker longitude={149.131} latitude={-35.2802} anchor="bottom">
                  <img width="40" height="40" src={pin} />
                </Marker>
              </Map>
            </Box>
          </Row>
          <Row>
            <Box className="blockTitle">
              <Link className="button" to="/">
                <span>
                  Email us
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <ContactForm />
    </ColumnContents>
  );
};

export default Section;
